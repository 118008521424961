body {
    background-color: #F2EDE4;
}

.content {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    /* accounts for header, footer and margins */
    /* ensures footer sticks to bottom on short content pages */
    min-height: calc(100vh - 200px); 
}

h1, h3 {
    margin: 0;
}

h2 {
    margin-top: 100px;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    font-size: 2em;
}

.montserrat-400 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.montserrat-800 {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
}

svg {
    font-size: 30px;
}

button {
    font-family: "Montserrat", sans-serif;
}

/*tablet*/
@media screen and (max-width: 1023px){
    h2 {
        margin-top: 60px;
    }
}

/*mobile*/
@media screen and (max-width: 767px){
    h2 {
        margin-top: 20px;
    }
}