header {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    background-color: #F2EEE2;
    z-index: 1000;
}

header h1 {
    font-size: 1.2em;
}

header a {
    text-decoration: none;
    cursor: pointer;
    color: #a63f03;
}

header a img {
    width: 40px;
    vertical-align: bottom;
}

header svg {
    color:#a63f03;
}

.navHeader {
    height: 80px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
}

header svg, .navigation svg {
    font-size: 30px;
    cursor: pointer;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigation li {
    padding: 10px;
    font-size: 1.5em;
}

.navigation a {
    text-decoration: none;
    color: #a63f03;
    cursor: pointer;
}

.navigation {
    position: fixed;
    right: 0;
    top: 0;
    background-color: white;
    z-index: 1000;
    width: 0;
    height: 0;
    text-align: center;
    align-items: center;
    overflow: hidden;
    background-color:  #F2EEE2;
    color: #a63f03;
    overflow-y: scroll;
    padding-bottom: 80px;
    box-sizing: border-box;
}

.navigation.isOpen {
    -webkit-transition: width 0.8s;
    -moz-transition: width 0.8s;
    transition: width 0.8s;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.navigation .divider {
    padding: 0;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1px;
    border-radius: 6px;
    background-color: white;
    padding-left: 30px;
    padding-right: 30px;
}

.navigation.isOpen {
    display: block;
}

.navigation .isActive a {
    font-weight: bold;
}


