.home {
  text-align: left;
}

.imageContainer {
  flex-grow: 1;
  background-image: url("./spadef.jpeg");
  width: 100%;
  background-color: #F2EEE2; /* Used if the image is unavailable */
  height: 450px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  border-radius: 20px;
}

.landing {
  min-height: calc(100vh - 80px); 
  background-color: #F2EEE2;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subheadingContainer {
  justify-content: left;
  display: flex;
  background-color: #0c5cbe;
  border-radius: 20px;
  padding: 100px;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subheading {
  font-size: 4.2em;
  text-align: left;
  padding: 0;
  margin: 0;
  align-content: center;
  color: #F2EEE2;
  font-weight: 600;
}

/*tablet*/
@media screen and (max-width: 1023px){=
	.subheading {
		font-size: 4em;
	}
}

/*mobile*/
@media screen and (max-width: 767px){
  .subheadingContainer {
    padding: 20px;
  }
	.subheading {
    font-size: 2em;
  }
  .imageContainer {
    height: 250px;
  }
  .landing img {
    margin-top: 20px;
  }
}

