.filtersActivitesContainer {
    background-color: #F2CB57;
    border-radius: 20px;
    padding: 20px;
}

.activities {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-right: -20px;
}

.activity {
    height: 250px;
    color: #000;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    vertical-align: top;
    position: relative;
    flex-basis: 31%;
    flex-grow: 0;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background-color: #F2EEE2 !important;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)
}

.cardTop h3 {
    font-size: 21px;
    font-weight: 800;
}

.cardSubtitle {
    padding: 0;
    margin: 0;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    /* text-transform: uppercase; */
    color: #5f5e5e;
}

.cardMiddle {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
    flex-grow: 1;
    align-content: flex-end;
}

.cardBottom {
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    align-items: flex-end;
}

.cardAction {
    background-color: unset;
    border: none;
    color: white;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
    box-sizing: border-box;
    margin-left: -10px;
    border-radius: 2px;
    font-size: 18px;
    margin-bottom: -10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 1000px;
    text-wrap: nowrap;
}

.cardAction:last-child {
    margin-left: 10px;
}

.cardAction a {
    color: #a63f03;
    text-decoration: none;
    font-size: 18px;
    box-sizing: border-box;
}

.cardAction:hover {
    background-color: #a63f0326;
}

@media (max-width: 999px) {
    .activity {
        flex-basis: 46%;
      }
  }

  @media (max-width: 558px) {
    .activity {
        flex-basis: 96%;
      }
  }