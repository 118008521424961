.about {
    text-align: center;
}

.about.content {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
}

.about p {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
}

.aboutImageContainer {
    background-image: url("./atom.jpg");
    width: 100%;
    background-color: #F2EEE2; /* Used if the image is unavailable */
    height: 450px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: inline-block;
    border-radius: 20px;
}

.aboutStory {
    width: 100%;
    display: inline-block;
    background-color: #F2CB57;
    padding: 100px;
    box-sizing: border-box;
    margin-top: 20px;
    text-align: left;
    border-radius: 20px;
}

.about a {
    text-decoration: none;
    color: #a63f03;
    cursor: pointer;
}

.about a:hover {
    text-decoration: underline;
}

.aboutHeader {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 4.2em;
    font-weight: 600;
    padding-bottom: 80px;
}

/*tablet*/
@media screen and (max-width: 1023px){
	.aboutHeader {
		font-size: 4em;
	}
}

/*mobile*/
@media screen and (max-width: 767px){
.aboutHeader {
	font-size: 2.5em;
  }
  .aboutStory {
    padding: 20px;
  }
}