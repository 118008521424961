.footer {
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
}

.footer a {
    cursor: pointer;
    color: #a63f03;
}

.footer .email {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    align-items: center;
    display: flex;
    font-size: 1.3em;
    border: 4px solid #0c5cbe;
    padding: 10px;
    background-color: #0c5cbe;
    color: #F2EEE2;
    border-radius: 20px;
}

.footer .email svg {
    margin-right: 6px;
}

.instaIcon, .fbIcon {
    margin-left: 16px;
    font-size: 35px;
}