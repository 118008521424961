.filtersHeader {
    display: flex;
    align-items: center;
}

.filtersContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.filtersCollapsible {
    height: 0;
    overflow: hidden;
}

.filtersCollapsible.isExpanded {
    -webkit-transition: height 0.8s;
    -moz-transition: height 0.8s;
    transition: height 0.8s;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.filters {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.filtersButton {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.2em;
    cursor: pointer;
    padding: 16px;
    border-radius: 1000px;
    border: 2.5px solid black;
    font-weight: 600;
    color: #F2EEE2;
    background-color: #000;
}

.filtersButton svg {
    font-size: 1em;
}

.filter {
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 12px;
    border-radius: 20px;
    /* width: 85px; */
    /* height: 85px; */
    height: 60px;
    text-align: left;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    color: #000;
    border: none;
    background-color: #F2EEE2;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
}

.filter .flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.filter:hover {
    border-color: #000;
}

.filter.isSelected {
    background-color: #000;
    color: #F2EEE2;
}

.filterName {
    /* padding-top: 2px; */
    padding-left: 6px;
}


.daysFilter {
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}

.dayFilter {
    font-size: 16px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #000;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #F2EEE2;
    cursor: pointer;
}

.dayFilter.isSelected {
    background-color: #000;
    color: #F2EEE2;
}

.showAll button {
    color: #a63f03;
    font-size: 1.2em;
    cursor: pointer;
    background-color: transparent;
    padding: 16px;
    border-radius: 1000px;
    border: 2.5px solid #a63f03;
    font-weight: 600;
    margin-left: 10px;
}

/*mobile*/
@media screen and (max-width: 767px){
    /* .filtersContainer {
      align-items: flex-end;
    }

    .daysFilter, .filters {
        justify-content: flex-end;
    }

    .showAll {
        text-align: right;
    } */
  }

  

